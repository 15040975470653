// Toast Message
import ToastMsg from '../../Components/Toast';
import { ToastProvider } from 'react-toast-notifications';
import MyAPI from '../../../../connections/Api.jsx';

import axios from 'axios';
import InputMask from 'react-input-mask';
import React, { useState, useEffect } from 'react';
import img from '../../../../assets/img/search.png';
import Card from '../../../../components/Card/Card.jsx';
import { codigo_e_banco } from '../../../../variables/Variables.jsx';
import Button from '../../../../components/CustomButton/CustomButton.jsx';
import PontosRamicardModal from '../../../Administrativos/Components/PontosRamicard/index.jsx';
import MapaModal from '../../../Administrativos/Components/Mapa/index.jsx';
import Spinner from "react-spinner-material";

import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  Image,
  FormControl,
  InputGroup,
} from 'react-bootstrap';

export const Editar = (props) => {
  // Toast Message
  const [error, setError] = useState([]);

  // - Variaveis do Card Canal
  const [refresh, setRefresh] = useState(false);
  const [infos_cep, selInformacoes] = useState([]);
  const [bancoSelect, Selecionarbanco] = useState([]);
  const [template_value, setTemplateValue] = useState(-1);
  const [canaisSelecionados, setCanaisSelecionados] = useState(
    props.dados_credenciados.canais_ativos
  );
  const [savingInfo, setSavingInfo] = useState(false);
  const [infoCredenciado, setInfoCredenciado] = useState({});
  const [showModalLocais, setShowModalLocais] = useState(false);  
  const [showModalMapa, setShowModalmapa] = useState(false);  

  useEffect(() => {
    async function getInfo() {
      try {
        const response = await MyAPI.getAccreditedInfo(props.dados_credenciados._id);
        if ( ! response.error ) {
          setInfoCredenciado(response.data);
        }
      } catch (e) {
        setError([{ message: "Não foi possível buscar as informações deste credenciado!", appearance: "error", auto_dismiss: true }]);
      }
    }

    getInfo()
  }, [props])
  

  async function Cadastrar(event) {
    event.preventDefault();

    let validations = 0;

    if (props.dados_credenciados.canais_ativos === null) {
      validations++;
      setError([
        ...error,
        {
          message: 'Selecione ao menos um canal a este credenciado!',
          title: 'error',
        },
      ]);
    }

    if (Number(props.dados_credenciados.dia_fechamento) > 31) {
      validations++;
      setError([
        ...error,
        { message: 'Selecione um dia de fechamento válido!', title: 'error' },
      ]);
    }

    if (
      String(props.dados_credenciados.cnpj_cpf).length !== 11 &&
      String(props.dados_credenciados.cnpj_cpf).length !== 14
    ) {
      validations++;
      setError([
        ...error,
        { message: 'CNPJ ou CPF inválido!', title: 'error' },
      ]);
    }

    try {
      if (validations === 0) {
        await MyAPI.postUpdateAccredited(
          props.dados_credenciados._id,
          props.dados_credenciados
        );

        setError([
          ...error,
          { message: 'Credenciado atualizado com sucesso!', title: 'success' },
        ]);

        setTimeout(() => window.location.reload(), 2000);
      }
    } catch (error) {
      setError([
        ...error,
        {
          message: 'Ocorreu um erro ao tentar cadastrar este Credenciado!',
          title: 'error',
        },
      ]);
    }
  }

  useEffect(() => setRefresh(false), [refresh]);

  function localiza(canais) {
    const dado = canaisSelecionados
      .map((element, index) => (element.canal === canais ? index : undefined))
      .filter((item) => item !== undefined);
    return dado[0];
  }

  const selecionar = (canal, taxa, parcelas) => {
    let dado = canaisSelecionados;
    const verifica = dado
      .map((element) => (element.canal === canal ? true : undefined))
      .filter((item) => item !== undefined);

    if (verifica.length > 0) {
      dado.splice(
        dado
          .map((element, index) =>
            element.canal === canal ? index : undefined
          )
          .filter((item) => item !== undefined),
        1
      );
      setCanaisSelecionados(dado);
    } else {
      dado.push({ canal: canal, taxa: taxa, parcelas_max: parcelas });
      setCanaisSelecionados(dado);
    }

    props.dados_credenciados.canais_ativos = dado;
    setRefresh(true);
  };

  const ativo = (canal) => {
    let dado = canaisSelecionados;
    const verifica = dado
      .map((element) => (element.canal === canal ? true : undefined))
      .filter((item) => item !== undefined);
    return !(verifica.length > 0);
  };

  async function cep(n_cep) {
    try {
      const informacoes = await axios.get(
        `https://viacep.com.br/ws/${n_cep}/json/`
      );
      selInformacoes(informacoes.data);

      props.dados_credenciados.endereco = informacoes.data.logradouro;
      props.dados_credenciados.bairro = informacoes.data.bairro;
      props.dados_credenciados.cidade = informacoes.data.localidade;
      props.dados_credenciados.estado = informacoes.data.uf;
    } catch (err) {
      alert('CEP inválido!', err);
    }
  }

  async function banco(n_banco) {
    try {
      const bancoSelecionado = codigo_e_banco.filter((item) =>
        item.codigo === Number(n_banco) ? item : null
      );
      Selecionarbanco(bancoSelecionado);
      props.dados_credenciados.banco = bancoSelecionado[0].nome;
    } catch (err) {
      alert('Banco não encontrado!');
    }
  }

  const selectTemplate = (value) => {
    const value_now = value.replace(/\D/g, '').length;
    setTemplateValue(
      template_value === value_now ? template_value + 1 : value_now
    );
    return value;
  };

  
  const closeModal = () => {
    setShowModalLocais(false)
  }

  const closeModalMapa = () => {
    setShowModalmapa(false)
  }

  const usarLocalSelecionado = (local) => {
    setInfoCredenciado(local);
  }

  const copiarDadosCadastro = () => {
    const { endereco, numero, bairro, cidade } = props.dados_credenciados;

    setInfoCredenciado({...infoCredenciado, endereco: `${endereco}, ${numero}, ${bairro}, ${cidade}`})
  }

  const doShowMapaModal = () => {
    if ( infoCredenciado && infoCredenciado.endereco && infoCredenciado.endereco.trim() !== '' ) {
      setShowModalmapa(true)
    } else {
      setError([
        ...error,
        {
          message: 'Você precisa fornecer o endereço antes de exibir o mapa.',
          title: 'error',
        },
      ]);
    }
  }

  const usarGeoSelecionada = (location) => {
    setInfoCredenciado({...infoCredenciado, latitude: location.lat, longitude: location.lng} );
  }

  const salvarInfoCredenciado = async () => {
    try {
      setSavingInfo(true);
      const response = await MyAPI.postAccreditedInfo(
        props.dados_credenciados._id,
        {...infoCredenciado, credenciado_id: props.dados_credenciados._id}
      );

      if ( response.error ) {
        setError([
          response.error,
          {
            message: 'Erro ao gravar informações do credenciado',
            title: 'error',
          },
        ]);
      } else {
        setError([
          ...error,
          { message: 'Informações do Credenciado gravadas com sucesso!', title: 'success' },
        ]);
      }

      setSavingInfo(false);
    } catch (e) {
      console.log(e);
      setError([
        ...error,
        { message: `Error: ${e.message}`, title: 'error' },
      ]);
      setSavingInfo(false);
    }
  }

  const removeInfoCredenciado = async () => {
    try {
      setSavingInfo(true);
      const response = await MyAPI.removeAccreditedInfo(
        props.dados_credenciados._id
      );

      if ( response.error ) {
        setError([
          response.error,
          {
            message: 'Erro ao gravar informações do credenciado',
            title: 'error',
          },
        ]);
      } else {
        setError([
          ...error,
          { message: 'Informações do Credenciado removidas com sucesso!', title: 'success' },
        ]);
        setInfoCredenciado({})
      }

      setSavingInfo(false);
    } catch (e) {
      console.log(e);
      setError([
        ...error,
        { message: `Error: ${e.message}`, title: 'error' },
      ]);
      setSavingInfo(false);
    }
  }

  return (
    <div className='content'>
      <Grid fluid>
        <PontosRamicardModal show={showModalLocais} closeModal={() => closeModal()} usarLocalSelecionado={(local) => usarLocalSelecionado(local)}/>
        <MapaModal show={showModalMapa} data={infoCredenciado} closeModal={() => closeModalMapa()} usarLocalSelecionado={(location) => usarGeoSelecionada(location)}/>
        <ToastProvider>
          <ToastMsg msg={error} clear={() => setError([])} />
        </ToastProvider>
        <Row>
          <Col md={12}>
            <Card
              title={
                <>
                  Credenciado
                  <br />
                  <br />
                  <Button
                    onClick={props.voltar}
                    style={{
                      backgroundColor: `${'#008000'}`,
                      color: '#FFF',
                      marginBottom: '20px',
                    }}
                    bsStyle='success'
                    simple={true}
                    type='button'
                  >
                    {' '}
                    Voltar
                  </Button>
                </>
              }
              content={
                <form style={{ margin: '10px' }} onSubmit={Cadastrar}>
                  <Row>
                    <Col md={3}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Razão Social*
                        </ControlLabel>
                        <FormControl
                          type='text'
                          required={true}
                          defaultValue={props.dados_credenciados.razao_social}
                          bsClass='form-control'
                          placeholder='Razão Social'
                          onChange={(event) =>
                            (props.dados_credenciados.razao_social =
                              event.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Nome Fantasia*
                        </ControlLabel>
                        <FormControl
                          type='text'
                          required={true}
                          bsClass='form-control'
                          defaultValue={props.dados_credenciados.nome_fantasia}
                          placeholder='Nome Fantasia'
                          onChange={(event) =>
                            (props.dados_credenciados.nome_fantasia =
                              event.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Inscrição Estadual
                        </ControlLabel>
                        <FormControl
                          type='text'
                          bsClass='form-control'
                          placeholder='Inscrição Estadual'
                          defaultValue={
                            props.dados_credenciados.incricao_estadual
                          }
                          onChange={(event) =>
                            (props.dados_credenciados.incricao_estadual =
                              event.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Inscrição Municipal
                        </ControlLabel>
                        <FormControl
                          type='text'
                          bsClass='form-control'
                          placeholder='Inscrição Municipal'
                          defaultValue={
                            props.dados_credenciados.incricao_municipal
                          }
                          onChange={(event) =>
                            (props.dados_credenciados.incricao_municipal =
                              event.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          CPF/CNPJ*
                        </ControlLabel>
                        <InputMask
                          required={true}
                          mask={
                            props.dados_credenciados.cnpj_cpf &&
                            template_value === -1
                              ? String(props.dados_credenciados.cnpj_cpf)
                                  .length === 14
                                ? '99.999.999/9999-99'
                                : '999.999.999-99'
                              : template_value > 11
                              ? '99.999.999/9999-99'
                              : '999.999.999-99'
                          }
                          className='form-control'
                          placeholder='CPF ou CNPJ'
                          defaultValue={props.dados_credenciados.cnpj_cpf}
                          onChange={(event) =>
                            (props.dados_credenciados.cnpj_cpf = selectTemplate(
                              event.target.value.replace(/\D/g, '')
                            ))
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={3}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Proprietário
                        </ControlLabel>
                        <FormControl
                          type='text'
                          bsClass='form-control'
                          placeholder='Proprietário'
                          defaultValue={props.dados_credenciados.proprietario}
                          onChange={(event) =>
                            (props.dados_credenciados.proprietario =
                              event.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Dia Fechamento*
                        </ControlLabel>
                        <FormControl
                          type='text'
                          required={true}
                          bsClass='form-control'
                          placeholder='Dia do Fechamento'
                          defaultValue={props.dados_credenciados.dia_fechamento}
                          onChange={(event) =>
                            (props.dados_credenciados.dia_fechamento =
                              event.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Email
                        </ControlLabel>
                        <FormControl
                          type='email'
                          bsClass='form-control'
                          placeholder='Email'
                          defaultValue={props.dados_credenciados.email}
                          onChange={(event) =>
                            (props.dados_credenciados.email =
                              event.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Telefone
                        </ControlLabel>
                        <FormControl
                          type='text'
                          bsClass='form-control'
                          placeholder='Telefone Fixo'
                          defaultValue={props.dados_credenciados.telefone}
                          onChange={(event) =>
                            (props.dados_credenciados.telefone =
                              event.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Celular
                        </ControlLabel>
                        <FormControl
                          type='text'
                          bsClass='form-control'
                          placeholder='Celular'
                          defaultValue={props.dados_credenciados.celular}
                          onChange={(event) =>
                            (props.dados_credenciados.celular =
                              event.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={1}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          WPP
                        </ControlLabel>
                        <div style={{ marginTop: '+10px', marginLeft: '23px' }}>
                          <input
                            type='checkbox'
                            name='q1_myOptions[]'
                            id='input_1_0'
                            defaultChecked={props.dados_credenciados.whatsapp}
                            onClick={() =>
                              (props.dados_credenciados.whatsapp =
                                !props.dados_credenciados.whatsapp)
                            }
                          />
                          <label htmlFor='input_1_0'></label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          CEP
                        </ControlLabel>
                        <FormControl
                          type='text'
                          bsClass='form-control'
                          placeholder='CEP'
                          defaultValue={props.dados_credenciados.cep}
                          onChange={(event) =>
                            (props.dados_credenciados.cep = event.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <Button
                        onClick={() => cep(props.dados_credenciados.cep)}
                        variant='contained'
                        bsStyle='info'
                        style={{
                          border: 0,
                          color: 'white',
                          borderRadius: 3,
                          fontSize: '14px',
                          marginLeft: '10px',
                          marginTop: '27px',
                          background: 'rgba(35,142,35, .8)',
                          boxShadow: '0 1px 1px 1px rgba(0, 0, 0, .2)',
                        }}
                      >
                        <Image
                          src={img}
                          style={{ width: '22px', height: '22px' }}
                        ></Image>
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Cidade
                        </ControlLabel>
                        <FormControl
                          type='text'
                          bsClass='form-control'
                          placeholder='Cidade'
                          defaultValue={
                            infos_cep.length <= 0
                              ? props.dados_credenciados.cidade
                              : infos_cep.localidade
                          }
                          onChange={(event) =>
                            (props.dados_credenciados.cidade =
                              event.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Estado
                        </ControlLabel>
                        <FormControl
                          type='text'
                          bsClass='form-control'
                          placeholder='Estado'
                          defaultValue={
                            infos_cep.length <= 0
                              ? props.dados_credenciados.estado
                              : infos_cep.uf
                          }
                          onChange={(event) =>
                            (props.dados_credenciados.estado =
                              event.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Endereço
                        </ControlLabel>
                        <FormControl
                          type='text'
                          bsClass='form-control'
                          placeholder='Endereço'
                          defaultValue={
                            infos_cep.length <= 0
                              ? props.dados_credenciados.endereco
                              : infos_cep.logradouro
                          }
                          onChange={(event) =>
                            (props.dados_credenciados.endereco =
                              event.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={1}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Número
                        </ControlLabel>
                        <FormControl
                          type='text'
                          bsClass='form-control'
                          placeholder='Número'
                          onChange={(event) =>
                            (props.dados_credenciados.numero =
                              event.target.value)
                          }
                          defaultValue={props.dados_credenciados.numero}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Bairro
                        </ControlLabel>
                        <FormControl
                          type='text'
                          bsClass='form-control'
                          placeholder='Bairro'
                          defaultValue={
                            infos_cep.length <= 0
                              ? props.dados_credenciados.bairro
                              : infos_cep.bairro
                          }
                          onChange={(event) =>
                            (props.dados_credenciados.bairro =
                              event.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Nº Banco
                        </ControlLabel>
                        <FormControl
                          type='text'
                          bsClass='form-control'
                          placeholder='Número'
                          onChange={(event) =>
                            (props.dados_credenciados.numero_banco =
                              event.target.value)
                          }
                          defaultValue={props.dados_credenciados.numero_banco}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <Button
                        onClick={() =>
                          banco(props.dados_credenciados.numero_banco)
                        }
                        variant='contained'
                        bsStyle='info'
                        style={{
                          border: 0,
                          color: 'white',
                          borderRadius: 3,
                          fontSize: '14px',
                          marginLeft: '10px',
                          marginTop: '27px',
                          background: 'rgba(35,142,35, .8)',
                          boxShadow: '0 1px 1px 1px rgba(0, 0, 0, .2)',
                        }}
                      >
                        <Image
                          src={img}
                          style={{ width: '22px', height: '22px' }}
                        ></Image>
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Nome do Banco
                        </ControlLabel>
                        <FormControl
                          type='text'
                          bsClass='form-control'
                          placeholder='Nome do Banco'
                          defaultValue={
                            bancoSelect.length <= 0
                              ? props.dados_credenciados.banco
                              : bancoSelect[0].nome
                          }
                          onChange={(event) =>
                            (props.dados_credenciados.banco =
                              event.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={1}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Agência
                        </ControlLabel>
                        <FormControl
                          type='text'
                          bsClass='form-control'
                          placeholder='Agência'
                          onChange={(event) =>
                            (props.dados_credenciados.agencia =
                              event.target.value)
                          }
                          defaultValue={props.dados_credenciados.agencia}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Nº Conta
                        </ControlLabel>
                        <FormControl
                          type='text'
                          bsClass='form-control'
                          placeholder='Número da Conta'
                          onChange={(event) =>
                            (props.dados_credenciados.conta =
                              event.target.value)
                          }
                          defaultValue={props.dados_credenciados.conta}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          CPF do Titular
                        </ControlLabel>
                        <FormControl
                          type='text'
                          bsClass='form-control'
                          placeholder='CPF do Titular'
                          onChange={(event) =>
                            (props.dados_credenciados.cpf_titular =
                              event.target.value)
                          }
                          defaultValue={props.dados_credenciados.cpf_titular}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Nome completo do Titular
                        </ControlLabel>
                        <FormControl
                          type='text'
                          bsClass='form-control'
                          placeholder='Nome do Titular'
                          onChange={(event) =>
                            (props.dados_credenciados.titular =
                              event.target.value)
                          }
                          defaultValue={props.dados_credenciados.titular}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={1}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Modalidade
                        </ControlLabel>
                        <FormControl
                          type='text'
                          bsClass='form-control'
                          placeholder='Modalidade'
                          onChange={(event) =>
                            (props.dados_credenciados.modalidade =
                              event.target.value)
                          }
                          defaultValue={props.dados_credenciados.modalidade}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {props.canais.length > 0 ? (
                    <Card
                      title='Canais de Prestação'
                      content={props.dados_credenciados.canais_ativos.map(
                        (item, key) => (
                          <Row key={key}>
                            <Col md={4}>
                              <FormGroup>
                                <ControlLabel style={{ color: '#000' }}>
                                  Nome do Serviço
                                </ControlLabel>
                                <FormControl
                                  type='text'
                                  bsClass='form-control'
                                  placeholder='Nome do Serviço'
                                  value={item.canal}
                                  readOnly
                                />
                              </FormGroup>
                            </Col>
                            <Col md={2}>
                              <FormGroup>
                                <ControlLabel style={{ color: '#000' }}>
                                  Taxa %
                                </ControlLabel>
                                <FormControl
                                  type='number'
                                  bsClass='form-control'
                                  placeholder='Taxa do Serviço'
                                  disabled={ativo(item.canal)}
                                  defaultValue={item.taxa}
                                  onChange={(event) =>
                                    (canaisSelecionados[
                                      localiza(item.canal)
                                    ].taxa = Number(event.target.value))
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col md={2}>
                              <FormGroup>
                                <ControlLabel style={{ color: "#000" }}>
                                  Max. Parcelas
                                </ControlLabel>
                                <FormControl
                                  type='number'
                                  bsClass='form-control'
                                  placeholder='Parcelas'
                                  disabled={ativo(item.canal)}
                                  value={item.parcelas_max}
                                  onChange={(event) =>
                                    (canaisSelecionados[
                                      localiza(item.canal)
                                    ].parcelas_max = event.target.value)
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col md={1}>
                              <FormGroup>
                                <ControlLabel style={{ color: '#000' }}>
                                  Ativo
                                </ControlLabel>
                                <div
                                  style={{
                                    marginTop: '10px',
                                    marginLeft: '10px',
                                  }}
                                >
                                  <input
                                    type='checkbox'
                                    defaultChecked={!ativo(item.canal)}
                                    onClick={() => {
                                      selecionar(
                                        item.canal,
                                        item.taxa,
                                        item.parcelas_max
                                      );
                                    }}
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                        ),
                      )}
                    />
                  ) : (
                    <></>
                  )}

                  <Card
                      title='Informações do Credenciado'
                      content={
                        <>
                          <Row>                        
                            <Col md={6}>
                              <FormGroup>
                                <ControlLabel style={{ color: "#000" }}>
                                Titulo do Credenciado
                                </ControlLabel>
                                <FormControl
                                  bsClass='form-control'
                                  placeholder='Nome Popular'                                  
                                  onChange={(event) =>
                                    (infoCredenciado.titulo =
                                      event.target.value)
                                  }
                                  defaultValue={infoCredenciado.titulo}
                                />
                              </FormGroup>                            
                            </Col>

                            <Col md={3}>
                              <FormGroup>
                                <ControlLabel style={{ color: "#000" }}>
                                Categoria
                                </ControlLabel>
                                <FormControl
                                  bsClass='form-control'
                                  placeholder='Categoria'
                                  onChange={(event) =>
                                    (infoCredenciado.categoria =
                                      event.target.value)
                                  }
                                  defaultValue={infoCredenciado.categoria}
                                />
                              </FormGroup>                            
                            </Col>

                            <Col md={3}>
                              <FormGroup>
                                <ControlLabel style={{ color: "#000" }}>
                                Especialidade
                                </ControlLabel>
                                <FormControl
                                  bsClass='form-control'
                                  placeholder='Especialidade (Opcional)'
                                  onChange={(event) =>
                                    (infoCredenciado.especialidade =
                                      event.target.value)
                                  }
                                  defaultValue={infoCredenciado.especialidade}
                                />
                              </FormGroup>                            
                            </Col>                          
                          </Row>
                          <Row>                        
                            <Col md={8}>
                              <FormGroup>
                                <ControlLabel style={{ color: "#000" }}>
                                  Endereço Completo
                                </ControlLabel>
                                <InputGroup>
                                  <FormControl
                                    bsClass='form-control'
                                    placeholder='Rua, Numero, Complemento e Bairro'
                                    onChange={(event) =>
                                      (infoCredenciado.endereco =
                                        event.target.value)
                                    }
                                    defaultValue={infoCredenciado.endereco}
                                  />
                                  <InputGroup.Button>
                                    <Button
                                      disabled={savingInfo} 
                                      bsStyle="sucess"
                                      type="button"
                                      onClick={() => copiarDadosCadastro()}>
                                      <i className="fa fa-copy" aria-hidden="true" />{" "}
                                        &nbsp;Copiar do Cadastro
                                    </Button>
                                    <Button
                                      disabled={savingInfo}                                      
                                      bsStyle="sucess"
                                      type="button"
                                      onClick={() => doShowMapaModal() }>
                                      <i className="fa fa-search" aria-hidden="true" />{" "}
                                        &nbsp;Ver no Mapa
                                    </Button>
                                  </InputGroup.Button>
                                </InputGroup>
                              </FormGroup>                            
                            </Col>

                            <Col md={2}>
                              <FormGroup>
                                <ControlLabel style={{ color: "#000" }}>
                                Telefone Contato
                                </ControlLabel>
                                <FormControl
                                  bsClass='form-control'
                                  placeholder='(99) 9999-9999'
                                  onChange={(event) =>
                                    (infoCredenciado.contato =
                                      event.target.value)
                                  }
                                  defaultValue={infoCredenciado.contato}
                                />
                              </FormGroup>                            
                            </Col>

                            <Col md={2}>
                              <FormGroup>
                                <ControlLabel style={{ color: "#000" }}>
                                Site
                                </ControlLabel>
                                <FormControl
                                  bsClass='form-control'
                                  placeholder='Site (Opcional)'
                                  onChange={(event) =>
                                    (infoCredenciado.site =
                                      event.target.value)
                                  }
                                  defaultValue={infoCredenciado.site}
                                />
                              </FormGroup>                            
                            </Col>                          
                          </Row>
                          <Row></Row>
                          <Row>
                            <Col md={11}>
                              <Button
                                disabled={savingInfo}
                                style={{
                                  backgroundColor: `${"#008000"}`,
                                  color: "#FFF",
                                  marginLeft: "10px",
                                }}
                                simple={true}
                                type="button"
                                onClick={() => setShowModalLocais(true) }
                              >
                              <i className="fa fa-search" aria-hidden="true" />{" "}
                                &nbsp;Localizar Local no Cadastrado
                              </Button>
                              <Button
                                disabled={savingInfo}
                                style={{
                                  backgroundColor: `${"#008000"}`,
                                  color: "#FFF",
                                  marginLeft: "10px",
                                }}
                                simple={true}
                                type="button"
                                onClick={() => salvarInfoCredenciado() }
                              >
                              <i className="fa fa-save" aria-hidden="true" />{" "}
                                &nbsp;Salvar Dados
                              </Button>
                              <Button
                                disabled={savingInfo}
                                style={{ background: "rgba(255,0,0, .8)", color: "rgb(255,255,255)", marginLeft: '15px', marginBottom: '0px', marginRight: '4px' }}
                                simple={true}
                                type="button"
                                onClick={() => removeInfoCredenciado() }
                              >
                              <i className="fa fa-save" aria-hidden="true" />{" "}
                                &nbsp;Remover Dados
                              </Button>

                            </Col>
                            <Col md={1}>
                              <Spinner size={400} spinnercolor={"#333"} spinnerwidth={2} visible={savingInfo} />
                            </Col>
                          </Row>                          
                        </>
                      }
                    />

                  <Row>
                    <Col md={12}>
                      <FormGroup
                        controlId='formControlsTextarea'
                        style={{ marginBottom: '-30px' }}
                      >
                        <ControlLabel style={{ color: '#000' }}>
                          Anotações
                        </ControlLabel>
                        <FormControl
                          rows='3'
                          componentClass='textarea'
                          bsClass='form-control'
                          placeholder='Anotação'
                          onChange={(event) =>
                            (props.dados_credenciados.anotacoes =
                              event.target.value)
                          }
                          defaultValue={props.dados_credenciados.anotacoes}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Button
                    type='submit'
                    variant='contained'
                    bsStyle='info'
                    style={{
                      marginTop: '35px',
                      marginRight: '10px',
                      background: 'rgba(35,142,35, .8)',
                      border: 0,
                      borderRadius: 3,
                      boxShadow: '0 1px 1px 1px rgba(0, 0, 0, .2)',
                      color: 'white',
                      fontSize: '14px',
                    }}
                  >
                    {' '}
                    Atualizar
                  </Button>
                  <Button
                    onClick={props.voltar}
                    variant='contained'
                    bsStyle='info'
                    style={{
                      marginTop: '35px',
                      background: 'rgba(35,142,35, .8)',
                      border: 0,
                      borderRadius: 3,
                      boxShadow: '0 1px 1px 1px rgba(0, 0, 0, .2)',
                      color: 'white',
                      fontSize: '14px',
                    }}
                  >
                    {' '}
                    Cancelar
                  </Button>
                </form>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};
